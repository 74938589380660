/* section Main */
.main {
  margin-top: 69px;
  background-color: rgb(1, 83, 156);
  background-image: url('../assets/images/images/graphic.png');
  background-size: cover;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.23) 0px 1px 0px;
  background-position: 0% 23%;
  padding-top: 70px;
  padding-bottom: 70px;
  display: flex;
  align-items: center;
}
.main-container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
}
.main-container-col {
  width: 50%;
}
.main-title {
  margin-top: 0px;
  margin-bottom: 20px;
  color: white;
  font-size: 38px;
  line-height: 44px;
}
.main-subtitle {
  margin-bottom: 26px;
  font-size: 20px;
  line-height: 26px;
  font-weight: 300;
}
.button-main {
  display: inline-block;
  margin-right: 14px;
  padding-top: 13px;
  padding-right: 23px;
  padding-bottom: 13px;
  padding-left: 23px;
  border-radius: 3px;
  background-color: rgb(253, 187, 48);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 1px 0px;
  color: white;
  text-align: center;
  text-decoration: none;
}
img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

@media (max-width: 991px) {
  .main-container {
    max-width: 95%;
  }
}
@media (max-width: 767px) {
  .main-container-col {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
  }
  .main-container {
    flex-direction: column;
  }
  #button-casual {
    width: 70% !important;
  }
}
/* section Press */

.press {
  padding-top: 29px;
  padding-bottom: 38px;
  border: 1px solid #dfe8e4;
  background-color: rgb(244, 244, 245);
}
.press-container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  justify-content: center;
}
.press-container img {
  margin: 0px 30px;
}
@media (max-width: 767px) {
  .press-container img {
    margin: 0px 20px;
    width: 100px;
  }
}
/* section Features */
.features {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #222222;
  text-align: center;
  padding-top: 70px;
  padding-bottom: 70px;
}
.feature-title {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
  text-align: center;
}
.feature-subtitle {
  margin-bottom: 68px;
  font-size: 19px;
  line-height: 22px;
  font-weight: 300;
  text-align: center;
}
.feature-row {
  display: flex;
  max-width: 80%;
}
.feature-col-2 {
  display: flex;
  width: 50%;
  align-items: flex-start;
  flex-direction: column;
  float: left;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}
.feature-icon {
  margin-top: 30px;
  margin-bottom: 12px;
}
.feature-col-2 p {
  font-size: 16px;
  line-height: 22px;
  text-align: left;
}
.small-feature {
  display: flex;
  max-width: 80%;
  margin-top: 71px;
}
.small-feature-col-3 {
  width: 25%;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 991px) {
  .feature-row,
  .small-feature {
    max-width: 95%;
  }
}
@media (max-width: 767px) {
  .feature-col-2,
  .small-feature-col-3 {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    align-items: center;
  }
  .feature-col-2 p,
  .small-feature-col-3 p {
    text-align: center;
  }
  .feature-row,
  .small-feature {
    flex-direction: column;
  }
  #viaticos-solicitud {
    flex-direction: column !important;
    align-items: center !important;
  }
  .content-acuerdos {
    width: 100% !important;
  }
  #button-navigation {
    width: 70%;
    align-self: center;
  }
  .navigation-button-registro {
    width: 70% !important;
  }
  .content-acuerdos {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-around;
  }
  .registro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #button-navigate-acuerdo {
    display: flow-root;
    text-align: center;
  }
  .div-inputs-nota {
    flex-direction: column-reverse !important;
    order: 1;
  }
  .order-input {
    order: 1 !important;
  }
}
/* Login */
.login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-container {
  display: flex;
  width: 260px;
  flex-direction: column;
  text-align: center;
  margin: 0 0 15px;
}
.login-input {
  display: block;
  width: 90%;
  height: 28px;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.428571429;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}
.login-submit {
  display: inline-block;
  height: 48px;
  width: 90%;
  padding: 9px 15px;
  background-color: rgb(80, 145, 205);
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}
.login-submit:disabled {
  background-color: lightblue;
  color: white;
}
.login-link {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  align-self: stretch;
  order: 1;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  font-size: 14px;
  font-style: normal;
  text-decoration: underline;
}
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-acuerdos {
  width: 75%;
  min-height: 60vh;
}
.calendar {
  width: 300px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  margin-bottom: 20px;
}

.button-programar {
  display: inline-block;
  margin-right: 14px;
  padding-top: 13px;
  padding-right: 23px;
  padding-bottom: 13px;
  padding-left: 23px;
  border-radius: 3px;
  background-color: #3898ec;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 1px 0px;
  color: #fff;
  text-align: center;
  text-decoration: none;
}

.div-button-programar {
  width: 90%;
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}
.MuiExpansionPanelSummary-content {
  align-items: center;
}
.seccion-reuniones {
  width: 90%;
  margin-top: 300px;
  margin-bottom: 20px;
}
@media (max-width: 376px) {
  .seccion-reuniones {
    margin-top: 370px;
  }
}
@media (max-width: 550px) {
  .button-programar {
    width: 100%;
  }
  .nav-button-programar {
    width: 90%;
  }
  .div-button-programar {
    justify-content: center;
  }
  .table-witdth-movil {
    display: none !important;
  }
  #date-inicio,
  #hora-inicio,
  #date-final,
  #hora-final {
    font-size: 10px;
  }
  .table-invitados {
    display: none !important;
  }
  .table-invitados-r {
    padding: 5px !important;
    font-size: 10px !important;
    text-align: center !important;
  }
  .Expand-more {
    color: white;
  }
  #button-navigation {
    width: 90%;
    align-self: center;
  }
  #inputs-viaticos {
    width: 100%;
  }
  #text-mensaje {
    width: 100% !important;
  }
  .buttons-modal-viaticos {
    flex-direction: column !important;
  }
  .button-modal-viaticos {
    width: 90% !important;
    margin-bottom: 5px !important;
    margin-left: 0px !important;
  }
  .nota-area-text {
    width: 90% !important;
  }
  .container-button-nota {
    width: 85% !important;
  }
}

#ProgramarReunion fieldset {
  display: none;
}

#ProgramarReunion input {
  border: 1px solid #cccccc;
}

#ProgramarReunion input:focus {
  border: 2px solid#01539C;
}

.inputActive input,
.inputActive select {
  position: relative;
  border: 2px solid #508e0d !important;
  width: 100%;
}

.inputError input,
.inputError select {
  position: relative;
  border: 2px solid red !important;
  width: 100%;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
  .MuiAutocomplete-input {
  padding: 18.5px 14px !important;
}
.fFOiIm {
  justify-content: space-evenly !important;
}

.MuiNativeSelect-select.MuiNativeSelect-select {
  height: 34px;
}
#cita-país {
  height: 34px;
}
/* 
#ProgramarReunion fieldset{
    border: 1px solid #cccccc;
}

#ProgramarReunion fieldset:focus{
    border: 2px solid#01539C;
}

.inputActive input, .inputActive select{
    position: relative;
    border: 2px solid #508E0D !important;
} */

.inputActive input::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100;
  border: 2px solid green;
  z-index: 100;
}

.tableHeader th {
  cursor: pointer;
  position: relative;
}

.tableHeader .headerTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableHeader .headerTitle p {
  margin: 0;
}

.tableHeader .headerTitle svg {
  color: #757575;
  margin-left: 0.5rem;
}
.filterActive .headerTitle svg {
  color: #096dd994;
}

.buttons-modal-viaticos {
  display: flex;
  @me flex-direction: row;
}

.nota-input-title {
  width: 90%;
  border: none;
  outline: 0;
  color: #090909;
  font-size: 18px;
  font: bold;
}
.nota-area-content {
  width: 100%;
  height: 100%;
  border: none;
  outline: 0;
  font-size: 14px;
}
.nota-area-text {
  width: 348px;
  max-width: 348px !important;
  min-height: 85px;
  border: 1px solid #333333;
  border-radius: 5px;
  padding: 15px;
}
.div-inputs-nota {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.container-button-nota {
  width: 356px;
  display: flex;
  justify-content: flex-end;
}

.calendar-button {
  display: flex;
  flex-direction: wrap;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  color: auto;
  width: 338px;
  height: 55px;
  margin-bottom: 10px;
  border: solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 100%;
}

.calendar {
  border: none !important;
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: none !important;
}
.resumen-input-area {
  width: 100%;
  border: none;
  background-color: #e5e5e5e5;
}

.resumen-input-area.active-input {
  border: 1px solid #757575 !important;
  background-color: #ffffff !important;
}

.snapshot {
  display: grid;
  grid-template-columns: 1fr;
  width: 95%;
  margin-right: 20px;
  max-width: 1600px;
  padding-top: 10%;
}

.snapshot__content {
  display: grid;
  grid-column-gap:15px;
  grid-template-columns: 1fr 1fr;
}

#main-snapshot {
  width: 100%;
}

.snapshot__content__column {
  width: 730px;
}

.snapshot_filter {
  text-decoration: underline;
  color: #257a66;
  cursor: pointer;
}

.snapshot__title {
  text-decoration: underline;
  color: #257a66;
  padding-top: 15px;
}

.snapshot__filters_container {
  padding: 15px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.snapshot__unlicensed_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 5px;
  margin-bottom: 10px;
}

.snapshot__cedente-list-container {
  width: 97%;
}

.snapshot__cedente-list-button {
  background-color: #01539c;
  color: white;
}

.snapshot__cedente-list-content {
  overflow-y: scroll;
  height: 100px;
}
.list-cedente {
  width: 100%;
  cursor: pointer;
  margin: 0px;
  margin-top: 3px;
}
.list-cedente:hover {
  background: gainsboro;
}

.snapshot__registry-date-container {
  width: 100%;
}

.snapshot__registry-title {
  background-color: #01539c;
  color: white;
}

.snapshot__select-dates-container {
  display: flex;
  margin-top: 10px;
}

.snapshot__unlicensed_content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}

.snapshot__filters_container_content {
  background-color: #01539c;
  color: white;
  width: 90%;
}

.snapshot__filters_container_select {
  width: 90%;
  margin-top: 10px;
}

.snapshot__content__first-header {
  display: flex;
}

.snapshot__pld-title {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.snapshot__options-container {
  display: flex;
}

.snapshot__crm-title-container {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

.snapshot__negocios-vigentes-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.snapshot__negocios-vigentes-content {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

.snapshot__center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.MTableToolbar-root-7 {
  display: none !important;
}